import ga from "react-ga";
import { Config } from "utils/Config";

export const trackEvent = (
  category: string,
  action: string,
  value?: number,
) => {
  // Disable events for development.
  if (Config.development)
    console.warn(
      `Dummy google analytics event. Category ${category}, action ${action}, value ${value}.`,
    );
  else ga.event({ category, action, value });
};

export const initializeGA = () => {
  const gaTrackingCode = Config.googleAnalytics.trackingCode;
  if (!gaTrackingCode)
    throw new Error(
      "No GA tracking code provided. Please fill it in the env file.",
    );
  ga.initialize(gaTrackingCode);
};

export const trackPageView = (path: string) => {
  ga.pageview(path);
};

import { createGlobalStyle } from "styled-components";
import { colors } from "styles/colors";

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth !important;
  }
  body {
    font-family: 'Barlow', sans-serif;
    background-color: rgb(239, 233, 230);
    margin: 0px;
    box-sizing: border-box;
  }
  * {
    -webkit-tap-highlight-color: transparent;
    ::selection {
      opacity: 1 !important;
      color: ${colors.white};
      background-color: ${colors.text_dark_main};
    }
  }
`;

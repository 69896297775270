import React, { FunctionComponent, useEffect, useState } from "react";
import { CookiesBoxButton, CookiesBoxWrapper } from "./CookiesBox.components";
import CookiesIcon from "./CookiesIcon.webp";
import gsap from "gsap";
import { areCookiesAccepted, setCookiesAccepted } from "./areCookiesAccepted";
import { useIsSmallTabletDown } from "components/legacy/useMedia";
import Image from "next/image";

const CookiesBox: FunctionComponent<{ couriersLanding?: boolean }> = ({
  couriersLanding,
}) => {
  const isTabletSmallDown = useIsSmallTabletDown();
  const [areAccepted, setAccepted] = useState(true);

  useEffect(() => {
    const cookiesAccepted = areCookiesAccepted();
    setAccepted(cookiesAccepted);
  }, []);

  const acceptCookiePolicy = () => {
    setCookiesAccepted();
    gsap
      .to(".cookiesBoxWrapper", { yPercent: 150, duration: 1, delay: 0.2 })
      .eventCallback("onComplete", () => {
        setAccepted(true);
      });
  };

  return !areAccepted ? (
    <CookiesBoxWrapper
      couriersLanding={couriersLanding}
      className="cookiesBoxWrapper"
    >
      <div style={{ width: 36, height: 36 }}>
        <Image
          src={CookiesIcon}
          width={36}
          height={36}
          layout="fixed"
          objectFit="contain"
          alt="Cookies box"
        />
      </div>
      <span>
        {!isTabletSmallDown && <>We use cookies.</>} By using our website you
        agree to use of our cookies.{" "}
        <a href="https://medicallogistics.co.uk/privacy-and-cookies-policy">
          Read cookies policies.
        </a>
      </span>
      <CookiesBoxButton onClick={acceptCookiePolicy}>Accept</CookiesBoxButton>
    </CookiesBoxWrapper>
  ) : (
    <></>
  );
};

export default CookiesBox;

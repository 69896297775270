import React, { FunctionComponent } from "react";
import Head from "next/head";

type PostHeadProps = {
  tag?: string;
};

const GeneralHead: FunctionComponent<PostHeadProps> = ({ tag }) => {
  const title = `${tag ? `Blog - ${tag}` : "Blog"} | Medical Logistics`;
  const description = tag
    ? `Check out the latest news about ${tag}!`
    : "Stay in the know with Medical Logistics.";
  const imageUrl = `https://blog.medicallogistics.co.uk/seo_banner.png`;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:type" property="og:type" content="website"></meta>
      <meta name="og:title" property="og:title" content={title}></meta>
      <meta
        name="og:description"
        property="og:description"
        content={description}
      ></meta>
      <meta name="og:image" property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Head>
  );
};

export default GeneralHead;

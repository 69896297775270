import type { AppProps } from "next/app";
import styled from "styled-components";
import { GlobalStyle } from "styles/globals";
import { LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import CookiesBox from "components/legacy/CookiesBox/CookiesBox";
import GeneralHead from "components/seo/GeneralHead";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { initializeGA, trackPageView } from "utils/googleAnalytics";
import { Footer, Navbar, Lines } from "@purpledeerstudio/logistics-components";
import "@purpledeerstudio/logistics-components/dist/style.css";

initializeGA();

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageContainer = styled.div`
  margin-top: 72px;
  margin-bottom: 72px;
  position: relative;
`;

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    // Split by # and get first element to prevent #tags in url.
    trackPageView(router.asPath.split("#")[0]);
    const handleRouteChange = (url: string) => trackPageView(url.split("#")[0]);
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <>
      <GeneralHead />
      <GlobalStyle />
      <Container>
        <CookiesBox couriersLanding />
        <Navbar variant="light" selection="blog" />
        <PageContainer>
          <LazyMotion features={domAnimation}>
            <AnimatePresence
              exitBeforeEnter={false}
              onExitComplete={() => window.scrollTo(0, 0)}
            >
              <Component {...pageProps} />
            </AnimatePresence>
          </LazyMotion>
        </PageContainer>
        <Footer
          variant="light"
          urls={{
            iso: "https://medicallogistics.co.uk/iso_certificate.pdf",
          }}
        />
        <Lines variant="light" />
      </Container>
    </>
  );
}

export default MyApp;

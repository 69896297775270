import { media } from "components/legacy/styles/media";
import styled, { css } from "styled-components";

export const CookiesBoxButton = styled.button`
  padding: 0;
  margin: 0;
  padding: 3px 10px 5px;
  background: #35aca8;
  border-radius: 8px;
  margin-left: 12px;
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #242729;
  transition: transform 0.3s ease-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const CookiesBoxWrapper = styled.div<{ couriersLanding?: boolean }>`
  z-index: 214748364;
  position: fixed;
  bottom: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  padding: 16px;
  background: rgba(35, 40, 41, 0.98);
  box-shadow: 0px 0px 1px rgba(96, 96, 96, 0.1);
  border-radius: 16px 16px 16px 0px;
  box-sizing: border-box;
  > span {
    max-width: 210px;
    font-size: 14px;
    line-height: 140%;
    color: #f0f5f5;
    margin-left: 12px;

    > a {
      color: #2e9e97;
      transition: transform 0.3s ease-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }

  ${media.down("TABLET_SMALL")`
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 0px;
    justify-content: space-between;
    >span {
        max-width: 100%;
    }
  `}

  ${(p) =>
    p.couriersLanding &&
    css`
      ${CookiesBoxButton} {
        background: #dd2735;
        color: #fff;
      }
      > span > a {
        color: #ff5b69;
      }
      svg path {
        fill: #ff5b69;
      }
      svg rect {
        fill: rgba(255, 91, 105, 0, 15);
      }
    `}
`;

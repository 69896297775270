export const COOKIES_ACCEPTED_KEY = "COOKIES_ACCEPTED";

export const areCookiesAccepted = () => {
  if (document) {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (COOKIES_ACCEPTED_KEY == cookiePair[0].trim()) {
        return true;
      }
    }
  } else {
    console.warn(
      "document is undefined. Call this function on client side with useEffect.",
    );
  }
  return false;
};

export const setCookiesAccepted = () => {
  if (document) {
    document.cookie = `${COOKIES_ACCEPTED_KEY}=true; domain=medicallogistics.co.uk; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  } else {
    console.warn(
      "document is undefined. Call this function on client side with useEffect.",
    );
  }
};

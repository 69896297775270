const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL;

export const Config = {
  development: process.env.NODE_ENV == "development",
  // NEXT_PUBLIC_VERCEL_URL is undefined in localhost.
  currentUrl: VERCEL_URL
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : "http://localhost:3000",
  contentful: {
    spaceId: process.env.CONTENTFUL_SPACE_ID!,
    enviroment: process.env.CONTENTFUL_ENVIRONMENT!,
    deliveryAccessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN!,
    previewAccessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN!,
  },
  pagination: {
    perPage: 5,
  },
  activeCampaign: {
    baseUrl: process.env.ACTIVE_CAMPAIGN_URL!,
    apiToken: process.env.ACTIVE_CAMPAIGN_API_TOKEN!,
  },
  googleAnalytics: {
    trackingCode: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_CODE!,
    category: "blog",
    action: "newsletter-subscription",
  },
};

export const colors = {
  dark: "#181A1B",
  text_main: "#1B0E0E",
  text_second: "#514D4D",
  text_second_white: "#CCCCCC",
  text_dark_main: "rgba(14, 27, 27, 1)",
  warm: "#EFE9E6",
  warm_lines: "rgba(164, 152, 152, 0.32)",
  white: "#FFFFFF",
  red: {
    _80: "#EED8D8",
    _500: "#DD2736",
    _600: "#CA1B2A",
  },
  mint: {
    _700: "#2C9B97",
    _600: "#35ACA8",
    _500: "#48C7C3",
  },
};
